import { getHotel, getHotelMenu, getRecommendedProduct } from "api/api";
import Decimal from "decimal.js";
import RecommandedProduct from "components/RecommandedProduct";
import { UUID_KY } from "enumeration";
import { useMemo } from "react";
import { useState, useEffect } from "react";
import { Alert, Badge, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import { changePriceFormat, numberWithCommas } from "utils/calculators";
import { useCategory } from "_context/category/provider";
import { useCarts } from "_context/shoppingCart/cartProvider";
import { useUuid } from "_context/uuid/uuidProvider";
import { useUtmSource } from "_context/utmSource/uuidProvider";
import "./style.scss";
import { pageView } from "../../GTM";
import NavBar from "../navbar";
import PageNavbar from "components/PageNavbar/pageNavbar";
import ProductCard from "components/isCartCard";
import EmptyCart from "components/emptyCart";
import styles from "./index.module.scss";
import ModalLayout from "components/ModalLayout";
import { useDiscount } from "_context/discount/discountProvider";
import Discount from "./Discount";

export const calculateValueWithPercent = (totalValue, partialValue) => {
	return new Decimal(totalValue || 0).div(100).mul(partialValue);
	//  (totalValue / 100) * partialValue;
};

const Cart = (props) => {
	console.log("props =====>", props);
	const [status, setStatus] = useState(["200"]);
	const [modalToggle, setModalToggle] = useState(false);
	const [show, setShow] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo({ top: 0 });
		// , behavior: "smooth"
	}, []);
	const { t, i18n } = useTranslation();

	const { addCount, cart, removeItem, addCart } = useCarts();
	const { data: catData } = useCategory();
	const discountState = useDiscount();

	const { data: uuid } = useUuid();
	const { data: utm, setLegalAge } = useUtmSource();

	const { data: hotel } = useQuery("hotel", () => getHotel(uuid), {
		retry: false,
	});

	const { data, isError, error, isLoading, isFetching, refetch } = useQuery(["recommended"], () => getRecommendedProduct(uuid), {
		// cacheTime: 0,
		// onSuccess: handleSuccess,
		retry: false,
	});

	console.log({ cart, hotel });

	const handleRemoveCart = (item) => {
		removeItem(item.data);
		console.log("removeItem ====>", item);
		// swal(t("successful"), t("removeFromBasket"), "success");
	};

	// const orderLimitValid = hotel?.orderLimit
	//   ? hotel?.orderLimit <= cart?.prices
	//   : true;
	// const disable = !orderLimitValid;
	let orderLimit = hotel?.orderLimit;

	const fee = 12.5;
	const serviceFee = hotel?.serviceCharge ? calculateValueWithPercent(cart?.prices, fee) : 0;

	useEffect(() => {
		pageView(window?.location?.pathname);
		console.log("pathName", window.location.pathname);
	}, []);

	const limitText = (text, limit) => {
		if (text?.length <= limit) {
			return text;
		}
		return text?.slice(0, limit) + "...";
	};

	const isIdVerificationRequired = useMemo(() => {
		return !![...(cart?.shoppingCart || [])]?.find((x) => x?.data?.isIdVerificationRequired);
	}, [cart]);

	let checkAge = !utm.legalAge && isIdVerificationRequired;
	const handleToggleLegalAgeModal = () => setModalToggle((p) => !p);
	const handleToggleDiscount = () => setShow((p) => !p);
	const handleDiscount = () => setShow(true);

	console.log({ checkAge, isIdVerificationRequired });
	let modalParams = {
		title: <div className={styles.modalTitle}>{t("modalLegalAgeTitle")}</div>,
		show: modalToggle,
		setShow: setModalToggle,
		dialogClassName: styles.dialogClassName,
	};

	// let total = cart?.prices || 0;
	// let totalWithService = total;
	// if (serviceFee) {
	// 	total = total + serviceFee;
	// 	totalWithService = total;
	// }

	// const orderLimitValid = orderLimit ? orderLimit <= +totalWithService : true;

	let total = new Decimal(cart.prices || 0);
	let totalWithService = total;
	if (serviceFee) {
		total = total.add(serviceFee);
		totalWithService = total;
	}
	// console.log({ discountState, total: +total, aa: +total.minus(discountState?.data?.amount) });

	if (discountState?.data?.amount) {
		if (discountState?.data?.isUnit) total = total <= discountState?.data?.amount ? 0 : total.minus(discountState?.data?.amount);
		else total = total.sub(total.div(100).mul(discountState?.data?.amount));
	}
	total = +total;
	if (total < 0) total = 0;
	// console.log({ discountState, total: +total });

	let isDiscount = discountState?.data?.amount != false && discountState?.data?.amount >= 0;

	const orderLimitValid = orderLimit ? orderLimit <= +totalWithService : true;
	const disable = !orderLimitValid;

	let reverseCart = useMemo(() => {
		return cart?.shoppingCart; //[...(cart?.shoppingCart || [])]?.reverse();
	}, [cart]);

	const filterData = useMemo(() => {
		return data?.result?.filter((x) => !cart?.object[x?.id]);
	}, [cart, data]);

	return (
		<div className="">
			<ModalLayout {...{ show, setShow, title: t("discount") }}>
				<Discount toggle={handleToggleDiscount} />
			</ModalLayout>
			<ModalLayout {...modalParams}>
				<div className={styles.modalText}>{t("modalLegalAgeText")}</div>

				<div className={styles.button_box}>
					<Button variant="outline-secondary" onClick={handleToggleLegalAgeModal}>
						{t("cancel")}
					</Button>

					<Button
						variant="success"
						onClick={() => {
							setLegalAge(true);
							handleToggleLegalAgeModal();
							navigate(`/payment`);
						}}>
						{t("modalLegalAcceptButton")}
					</Button>
				</div>
			</ModalLayout>
			<div className={styles.navbar}>
				<PageNavbar
					text={t("myCart")}
					path={catData?.child?.id ? `/product/${catData?.child?.id}` : catData?.parent?.id ? `/product/${catData?.parent?.id}` : "/"}
				/>
			</div>
			<div className={styles.body}>
				{!cart?.shoppingCart?.length ? (
					<div className={[styles.cartItems, !filterData?.length && styles.full].join(" ")}>
						<EmptyCart />
					</div>
				) : (
					<div className={[styles.cartItems, !filterData?.length && styles.full].join(" ")}>
						{reverseCart?.map((x) => {
							const isTranslate = {};
							x?.data?.translations?.forEach((y) => (isTranslate[y?.language] = y));
							// const textTranslate = isTranslate[i18n?.language];
							// console.log("newCard", cart?.shoppingCart);
							return <ProductCard className="mt-4" data={x} key={x?.data} handleRemoveCart={handleRemoveCart} />;
						})}
					</div>
				)}
				{/* 
        {reverseCart?.map((x, i) => {
          const isTranslate = {};
          x?.data?.translations?.forEach((y) => (isTranslate[y?.language] = y));
          // const textTranslate = isTranslate[i18n?.language];
          // console.log("newCard", cart?.shoppingCart);
          return (
            <ProductCard
              className="mt-4"
              data={x}
              key={x?.data}
              handleRemoveCart={handleRemoveCart}
            />
          );
        })} */}

				{/* {<div className={styles["empty-space"]} />} */}
				<div className={styles.fixedBottom}>
					<div className={["maxContent bg-white"].join(" ")}>
						<div className={["shadow bg-white rounded  clearfix comeTop flex-direction-column border-top", styles.calculatorBox].join(" ")}>
							<div className="p-2">
								<span className=" ">{t("products")}</span>
								<span className="float-right">£ {numberWithCommas(changePriceFormat(cart.prices))}</span>
							</div>
							<div className="p-2">
								<span className=" " data-lang="hizmet_bedeli">
									{/* {t("trayCharge")} */}
									{"Tray Charge"}
								</span>
								<span className="float-right">£ {isNaN(serviceFee) ? "0" : numberWithCommas(changePriceFormat(serviceFee))}</span>
							</div>

							<div
								className={[styles.discount, "p-2", isDiscount && styles.active].join(" ")}
								onClick={() => {
									!isDiscount && handleDiscount();
								}}>
								<i className={["fa-solid fa-tag", styles.icon].join(" ")}></i>
								<span className={styles.label} data-lang="hizmet_bedeli">
									{isDiscount ? t("discount") : t("useDiscountCode")}
								</span>
								<span className="float-right">
									{isDiscount ? (
										<div>
											{discountState?.data?.isUnit ? "£ " : ""}
											{numberWithCommas(discountState?.data?.amount)}
											{discountState?.data?.isUnit ? " " : "%"}
										</div>
									) : (
										<Badge className="p-1" bg="success">
											{t("apply")}
										</Badge>
									)}
								</span>
							</div>
						</div>
						{filterData?.length ? <RecommandedProduct /> : ""}
					</div>
					{cart?.shoppingCart?.length ? (
						<div className="continue-button-container" disabled={disable}>
							<Link
								to={{
									pathname: disable ? "/cart" : `/payment`,
									// search: `?${[UUID_KY]}=${uuid}`,
								}}
								className={`continue-button btn-block btn-lg ${disable ? styles["disabled"] : ""}`}
								style={{ color: "#fff" }}
								id="odemeYap"
								data={status}
								disabled={disable}
								onClick={
									checkAge
										? (e) => {
												e.preventDefault();
												handleToggleLegalAgeModal();
										  }
										: null
								}>
								<div className="button-container">
									<button className="button button-left">
										{disable ? t("minimumCart", { author: "£ " + (orderLimit || 0)?.toFixed?.(2) }) : t("continue")}
									</button>
									<button className="button button-right d-flex flex-column py-0 align-items-center justify-content-center">
										{isDiscount && cart && cart.prices ? (
											<span className={styles.beforePrice}>{numberWithCommas(changePriceFormat(totalWithService))}</span>
										) : (
											""
										)}
										<div className={["d-flex", isDiscount && "text-success"].join(" ")}>
											<span> {"£ "}</span>
											<span> {numberWithCommas(changePriceFormat(total))} </span>
										</div>
									</button>
								</div>
							</Link>
						</div>
					) : (
						<div className="continue-button-container">
							<div className={`continue-button btn-block btn-lg disabled`} style={{ color: "#fff" }} id="odemeYap" data={status}>
								<div className="button-container">
									<button className="button button-left-disabled">{t("continue")}</button>
									<button className="button button-right">{cart && cart.prices ? "£" + cart.prices.toFixed(2) : "£0"}</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Cart;
