const baseStrings = {
	translation: {
		errors: {
			// "is-require": "{{author}} is require",
			"is-require": "This field is required",
			"is-not-valid": "{{author}} is not valid",
			"please-enter-your": "Please enter your {{author}}",
			"please-enter": "Please enter {{author}}",
			"please-select-your": "please select your {{author}}",
			"must-be": "{{author}} must be {{kind}}",
			min: "The lowest character is {{author}}",
			max: "The maximum number of characters is {{author}}",
		},
		home: " Home page ",
		menu: " Menu ",
		opportunity: " Special ",
		opportunityProducts: " Special Offers ",
		payment: " Payment ",
		back: "Back",
		yourName: " Full Name ",
		roomNumber: " Room Number ",
		deliveryInfo: " Guest Information ",
		yourDeliveryInfo: " Guest Information ",
		paymentInfo: " Payment Information ",
		productTotal: " Product Total ",
		serviceFee: " Service Fee ",
		trayCharge: "Tray Charge",
		total: " Total Amount ",
		online: "Pay Online",
		card: " Pay On Delivery With Card  ",
		myCart: " My Cart ",
		cash: " Cash ",
		note: " Note ",
		delivery: " Delivery ",
		payment: " Payment ",
		orderNote: "Please let us know if you have any food allergies, special dietary needs or any specific request for our team",
		completeOrder: " Complete Order ",
		enjoyYourMeal: " Enjoy your meal!",
		yourOrderReceived: " Your order has been received ",
		// completeOrder: " Your Order is Completed ",
		hello: " Hello. ",
		uuiError: " You can enter by simply scanning the QR code.",
		successful: " Successful ",
		productAddToCart: " Product Added to Cart!",
		addToBasket: " Add to Cart ",
		removeFromBasket: " Remove from Cart ",
		productTitle: " PERSONAL CARE ",
		itemNotFound: " Item Not Found ",
		completeTheOrder: " Please Complete the Order ",
		selectThePayment: " Please Select Payment Type ",
		paymentType: " Payment method ",
		orderLimitAuthor: " Order Limit {{author}} {{currency}}",
		orderLimit: " Order Limit ",
		dataNotFound: " No data found ",
		confirmOrder: " Tap to Complete the Order ",
		exchangeInfo: " If you pay in foreign currency, your change given in Turkish Lira",
		deliveryInfo: " Please make sure that you write your name and room number information completely so that your order can be delivered correctly..",
		weather: " Weather ",

		totalEuro: " Total Amount (Euros)",
		totalDollar: " Total Amount (Dollars)",
		totalTL: " Total Amount (TL)",
		diss: " Discount on All Products Special for Today!",

		continue: "Continue",
		cashCurrency: "Select Currency",
		other: "Other Categories",

		// preparing: "Your order is being prepared Enjoy your meal...",
		preparing: "Thank you! We've received your order. Please don't forget to check your inbox for the details.",

		notValid: "Enter Full name and Room Number",

		deviceError: "Tablet or mobile screen size is allowed. Allowed page size is less than 768 pixels",
		searchHome: "Search ...",
		notfound: "Data Not found",
		notfoundTitle: "Not found",
		notfoundText: "Please enter your search text more precisely",
		searchText: "Searching ...",
		searchLabel: "search",
		"back-to-menu": "Back to menu",
		bankErrorTitle: "Payment failed",
		bankErrorDescription: "There is a problem with payment. Please check your card information and try again",
		minimumCart: "minimum : {{author}}",
		modalLegalAgeTitle: "Are you old enough to buy age restricted items ?",
		modalLegalAgeText:
			"Age restricted items (including alcohol and tobacco) are not for sale to anyone under the age of 18. You may need to provide a valid ID upon receiving your order",
		modalLegalAcceptButton: "yes, I am 18 or over",
		cancel: "cancel",
		medicineTitle: "Pain relief products have a limit of 2 per order",
		phoneNumber: "Phone number",
		phoneNumberDelivery: "Phone number:Our Delivery Will Call You",
		phoneNumberCourier: "Phone number: Our Courier Will Call You",
		useDiscountCode: "Apply a discount code",
		codeCheck: "Apply",
		discount: "Discount",
		discountAuthor: "Discount ({{author}})",
		products: "Products",
		append: "Append",
		code: "Code",
		checkAuthor: "Check {{author}}",
		"discount-note": "If you have a discount code, please enter",
		"discount-code-details": "The code you provided is {{code}}. The name of this discount code is {{name}} and it has a value of {{amount}}% discount",
		"back-to-menu": "Back to menu",
		bankErrorTitle: "Payment failed",
		bankErrorDescription: "There is a problem with payment. Please check your card information and try again",
		minimumCart: "minimum : {{author}}",
		orderNow: "Order now",
		AppointmentDaySelection: "Appointment Day Selection",
		email: "Email",
		apply: "Apply",
		allergyText: `Food may contain or contact allergens. Please note any food allergies in the "Notes" at checkout`,
		notePlaceholder: "Food may contain or contact allergens. Please note any food allergies!",
	},
};

export const en = baseStrings;
