const baseStrings = {
	translation: {
		home: "Ana sayfa",
		menu: "Menü",
		opportunity: "Özel",
		opportunityProducts: "Özel Teklifler",
		payment: "Ödeme",
		back: "Back",
		yourName: "Adınız",
		roomNumber: "Oda Numarası",
		deliveryInfo: "Misafir Bilgileri",
		yourDeliveryInfo: "Misafir Bilgileri",
		paymentInfo: "Ödeme Bilgileri",
		productTotal: "Ürün Toplamı",
		serviceFee: "Servis Ücreti",
		trayCharge: "Tepsi Ücreti",
		total: "Toplam Tutar",
		online: "Online",
		card: "Kredi Kartı",
		myCart: "Sepetim",
		cash: "Nakit",
		note: "Not",
		delivery: "Teslimat",
		payment: "Ödeme",
		orderNote: "Sipariş notunuzu girin",
		completeOrder: "Siparişi Tamamla",
		enjoyYourMeal: "Afiyet olsun!",
		yourOrderReceived: "Siparişiniz Alındı",
		// completeOrder: "Siparişiniz Tamamlandı",
		hello: "Merhaba",
		uuiError: "Sadece QR kodu tarayarak girebilirsiniz.",
		successful: "Başarılı",
		productAddToCart: "Ürün Sepete Eklendi!",
		addToBasket: "Sepete ekle",
		removeFromBasket: "Sepetten çıkar",
		productTitle: "KİŞİSEL BAKIM",
		itemNotFound: "Öğe Bulunamadı",
		completeTheOrder: "Lütfen Siparişi Tamamlayın",
		selectThePayment: "Lütfen Ödeme Türünü Seçin",
		paymentType: "Ödeme şekli",
		orderLimitAuthor: "Sipariş Sınırı {{author}} {{currency}}",
		orderLimit: "Sipariş Limiti",
		dataNotFound: "Veri bulunamadı",
		confirmOrder: "Siparişi Tamamlamak İçin Dokunun",
		exchangeInfo: "Eğer yabancı para birimiyle öderseniz, Para üstü Türk Lirası olarak verilir.",
		deliveryInfo: "Siparişinizin doğru şekilde teslim edilebilmesi için lütfen isim ve oda numarası bilgilerinizi eksiksiz yazdığınızdan emin olunuz.",
		weather: "Hava Durumu",
		totalEuro: "Toplam Tutar (Euro)",
		totalDollar: "Toplam Tutar (Dolar)",
		totalTL: "Toplam Tutar (TL)",
		diss: "Bugüne Özel Tüm Ürünlerde İndirim!",
		continue: "Devam",
		cashCurrency: "Para Birimini Seçiniz",
		other: "Diğer Kategoriler",
		// preparing: "Siparişiniz Hazırlanıyor Afiyet Olsun...",
		preparing: "Teşekkürler! Siparişinizi aldık, detaylar için e-posta kutunuzu kontrol etmeyi unutmayın.",

		notValid: "Ad Soyad ve Oda Numarası Giriniz",
		searchHome: "Ara ...",
		notfound: "Veri Bulunamadı",
		"back-to-menu": "Menüye dön",
		bankErrorTitle: "Ödeme başarısız oldu",
		bankErrorDescription: "Ödemede sorun oluştu. Lütfen kart bilgilerinizi kontrol edip tekrar deneyin",
		minimumCart: "minimum : {{author}}",
		modalLegalAgeTitle: "Yaş sınırlamalı öğeleri satın alabilecek yaşta mısınız?",
		modalLegalAgeText:
			"Yaş kısıtlamalı ürünler (alkol ve tütün dahil) 18 yaşın altındaki kişilere satılmamaktadır. Siparişinizi alırken geçerli bir kimlik belgesi ibraz etmeniz gerekebilir",
		modalLegalAcceptButton: "evet, 18 yaşında veya daha büyüğüm",
		cancel: "iptal etmek",
		medicineTitle: "Ağrı kesici ürünlerin sayı başına 2 sınırı vardır",
		phoneNumber: "telefon numarası",
		phoneNumberDelivery: "Telefon numarası: Teslimatçımız Sizi Arayacaktır",
		phoneNumberCourier: "Telefon numarası : Kuryemiz Sizi Arayacaktır",
		useDiscountCode: "İndirim kodunuzu kullanın",
		discount: "İndirim",
		discountAuthor: "İndirim ({{author}})",
		products: "Ürünler",
		append: "Ekle",
		code: "Kod",
		checkAuthor: "{{author}}'u kontrol et",
		"discount-note": "İndirim kodunuz varsa lütfen giriniz",
		"discount-code-details": "Verdiğiniz kod {{code}}. Bu indirim kodunun adı {{name}} olup, %{{amount}} indirim değerine sahiptir",
		"back-to-menu": "Menüye dön",
		bankErrorTitle: "Ödeme başarısız oldu",
		bankErrorDescription: "Ödemede sorun oluştu. Lütfen kart bilgilerinizi kontrol edip tekrar deneyin",
		minimumCart: "minimum : {{author}}",
		orderNow: "Şimdi sipariş verin",
		AppointmentDaySelection: "Randevu Günü Seçimi",
		email: "E-posta",
		apply: "başvurmak",
		allergyText: `Yiyecekler alerjen içerebilir veya alerjenlerle temas edebilir. Lütfen "Notlar" bölümünde gıda alerjilerinizi belirtin.`,
		notePlaceholder: "Yiyecekler alerjen içerebilir veya alerjenlerle temas edebilir. Lütfen gıda alerjilerinizi belirtin!",
	},
};

export const tr = baseStrings;
